import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private acRoute: ActivatedRoute) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    
    if(sessionStorage.getItem('loggedIn') == 'true'){

      return true;

    } else {
      
      this.router.navigate([`${localStorage.getItem('eventPrefix')}/login`])

      return false;
      

    }

  }
}

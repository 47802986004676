import { Component, OnInit, HostListener, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";

import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { DomSanitizer } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: "app-full-layout",
  templateUrl: "./full.component.html",
  styleUrls: ["./full.component.scss"],
})
export class FullComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private _sanitizer: DomSanitizer
  ) {}

  public innerWidth: any;
  public defaultSidebar: any;
  public showMobileMenu = false;
  public expandLogo = false;
  public sidebartype = "full";
  public user: any;
  public userName: any;
  public userIcon: any;
  public index = localStorage.getItem("eventPrefix");
  public currentYear: number;

  @ViewChild("numB", { static: false }) numB: any;

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();

    if (this.router.url === "/") {
      this.router.navigate([`${localStorage.getItem("eventPrefix")}/home`]);
    }
    this.defaultSidebar = this.sidebartype;
    this.handleSidebar();

    this.user = JSON.parse(sessionStorage.getItem("user"));

    if (this.user.name != "") {
      this.userName = this.user.name;
    } else {
      this.userName = this.user.cpf;
    }

    if (this.user.photo) {
      this.userIcon = this._sanitizer.bypassSecurityTrustResourceUrl(
        this.user.photo
      );
    } else {
      this.userIcon = "assets/images/users/user3.png";
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 767) {
      this.sidebartype = "mini-sidebar";
    } else {
      this.sidebartype = this.defaultSidebar;
    }
  }

  toggleSidebarType() {
    switch (this.sidebartype) {
      case "full":
        this.sidebartype = "mini-sidebar";
        break;

      case "mini-sidebar":
        this.sidebartype = "full";
        break;

      default:
    }
  }

  logout() {
    let prefix = this.route.snapshot.params.event;
    sessionStorage.removeItem("loggedIn");
    sessionStorage.removeItem("hasCashback");
    sessionStorage.removeItem("hasRecharge");
    sessionStorage.removeItem("deviceId");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("eventId");
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("onlineCashlessCash");
    sessionStorage.removeItem("onlineUsername");
    sessionStorage.removeItem("onlinePassword");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("cashbackTax");
    sessionStorage.removeItem("cashbackMinValue");
    sessionStorage.removeItem("eventName");
    sessionStorage.removeItem("cashlessChargeBackRules");
    sessionStorage.removeItem("eventVendorEmail");
    sessionStorage.removeItem("gatewayInfo");
    this.router.navigate([`${prefix}/login`]);
  }

  closeMenu() {
    let element: HTMLAnchorElement = this.numB.nativeElement;

    element.click();
  }
}

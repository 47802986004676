import { RouteInfo } from "./sidebar.metadata";
import { environment } from "../../../environments/environment";

export const ROUTES: RouteInfo[] = [
  {
    path: "home",
    title: "Página Inicial",
    icon: "mdi mdi-home",
    class: "",
    extralink: false,
    submenu: [],
    hidden: false,
  },
  // {
  //   path: "perfil",
  //   title: 'Meu Perfil',
  //   icon: 'mdi mdi-account-circle',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  {
    path: "recargaOnline",
    title: "Recarga Online",
    icon: "mdi mdi-cart-outline",
    class: "",
    extralink: false,
    submenu: [],
    hidden: false,
  },
  {
    path: "recargas",
    title: "Lista de Recargas Online",
    icon: "mdi mdi-format-list-bulleted",
    class: "",
    extralink: false,
    submenu: [],
    hidden: false,
  },
  {
    path: "extrato",
    title: "Extrato",
    icon: "mdi mdi-receipt",
    class: "",
    extralink: false,
    submenu: [],
    hidden: false,
  },
  {
    path: "entradas",
    title: "Entradas",
    icon: "mdi mdi-walk",
    class: "",
    extralink: false,
    submenu: [],
    hidden: true,
  },
  // {
  //   path: "https://REPLACE.nowigo.com.br/app/cashlesschargeback.mdl?action=login",
  //   title: "Reembolso",
  //   icon: "mdi mdi-cash-usd",
  //   class: "",
  //   extralink: false,
  //   submenu: [],
  //   externalLink: true,
  //   hidden: true,
  // },
];

import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';

import { AuthGuard } from './authentication/guards/auth.guard';
import { LoginGuard } from './authentication/guards/login.guard';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';

const hasEvent = localStorage.getItem("eventPrefix")

export const Approutes: Routes = [
  {
    path: ':event',
    component: FullComponent,
    children: [
      { path: '', redirectTo: hasEvent ? `/${hasEvent}/login` : '/', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren: './starter/starter.module#StarterModule',
        canActivate: [AuthGuard]
      },
      {
        path: '',
        loadChildren: './component/component.module#ComponentsModule',
        canActivate: [AuthGuard]
      }

    ]
  },
  {
    path: ':event',
    component: AppBlankComponent,
    children: [
    {
      path: '',
      loadChildren: './authentication/auth.module#AuthModule',
      canActivate: [LoginGuard]
    }
  ]

  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(Approutes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {}

import { Title } from "@angular/platform-browser";
import {
  Component,
  AfterViewInit,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { ROUTES } from "./menu-items";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { RouteInfo } from "./sidebar.metadata";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  showMenu = "";
  showSubMenu = "";
  user = null;
  userCpf = "";
  eventPrefix = "";

  @Output()
  clickSidebar = new EventEmitter();

  public sidebarnavItems: RouteInfo[];
  // this is for the open close
  addExpandClass(element: any) {
    this.clickSidebar.emit(true);

    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  // End open close
  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.userCpf = this.user.cpf.replace(/[^\d]/g, "");

    this.eventPrefix = localStorage.getItem("eventPrefix");

    this.sidebarnavItems = ROUTES.filter((sidebarnavItem) => sidebarnavItem);

    const method = localStorage.getItem("@recargaOnline:method_identifier");

    // ********** TODO remover essa gambiarra **************
    const events = ['314','315','316','317'];

    const eventId = sessionStorage.getItem("eventId");

    let withoutItem = events.includes(eventId);
    // ********** TODO remover essa gambiarra **************

    for (let item of this.sidebarnavItems) {
      if (
        item.path === "recargaOnline" &&
        (!JSON.parse(sessionStorage.getItem("hasRecharge")) ||
          method.length === 0)
      ) {
        this.sidebarnavItems.splice(this.sidebarnavItems.indexOf(item), 1);
      }

      if (withoutItem && item.hidden) {
        this.sidebarnavItems = this.sidebarnavItems.filter(obj => obj !== item);
      }

      if (item.externalLink) {
        const newUrl = item.path.replace("REPLACE", this.eventPrefix);
        item.path = newUrl + "&cpf=" + this.userCpf;
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate  {

  constructor(private router: Router, private acRoute: ActivatedRoute) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    
    if(sessionStorage.getItem('loggedIn') == 'true'){

      this.router.navigate([`/${localStorage.getItem('eventPrefix')}/home`])

      return false;

    } else {

      return true;
      

    }

  }

}

import { Component, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  @Output()
  toggleSidebar = new EventEmitter<void>();

  public showSearch = false;

  constructor(private router: Router, private route: ActivatedRoute) {}

  logout(){
    let prefix = this.route.snapshot.params.event;
    sessionStorage.removeItem('loggedIn');
    sessionStorage.removeItem('hasCashback');
    sessionStorage.removeItem('hasRecharge');
    sessionStorage.removeItem('deviceId');
    sessionStorage.removeItem('eventId');
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('onlineCashlessCash');
    sessionStorage.removeItem('onlineUsername');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('onlinePassword');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('cashbackTax');
    sessionStorage.removeItem('cashbackMinValue');
    sessionStorage.removeItem('eventName');
    sessionStorage.removeItem('cashlessChargeBackRules');
    this.router.navigate([`${prefix}/login`]);
  }

}

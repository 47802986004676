export const environment = {
  production: true,
  tel: "34733131",
  area: "35",
  hasCashback: false,
  hasRecharge: false,
  rechargeLimit: 200.00,
  rechargeBalanceLimit: 500.00,
  url: "https://api.reconline.nowigo.com.br",
};
